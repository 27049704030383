<template>
    <div>
        <div>
            <table class="table-striped table-responsive">
                <thead>
                <tr>
                    <th>Instalación</th>
                    <th>Clasificación</th>
                    <th>Documento</th>
                    <th>Mantenedor</th>
                    <th>Fecha realizada</th>
                    <th>Fecha prevista</th>
                    <th>Tipologia</th>
                    <th>Tipo Instalación</th>
                    <th>Responsable</th>
                    <th>Empresa</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(detail, installation) in installation_list"
                    :key="installation">
                    <td>{{ detail.gei_code }}</td>
                    <td>{{ detail.classification }}</td>
                    <td>{{ detail.document_name }}</td>
                    <td>{{ detail.provider_contract_e_e && detail.provider_contract_e_e[0] && detail.provider_contract_e_e[0].provider && detail.provider_contract_e_e[0].provider.provider ? detail.provider_contract_e_e[0].provider.provider : '' }}</td>
                    <td>{{ detail.effective_date ? helpers.formatDate(detail.effective_date) : '-' }}</td>
                    <td>{{ detail.expected_date ? helpers.formatDate(detail.expected_date) : '-' }}</td>
                    <td>{{ detail.typology ? detail.typology.name : '' }}</td>
                    <td>{{ detail.installation_type ? detail.installation_type.type : '' }}</td>
                    <td>{{ detail.master_installation.responsible ? detail.master_installation.responsible.name : '' }}</td>
                    <td>{{ detail.master_installation.company ? detail.master_installation.company.name : '' }}</td>
                </tr>
                </tbody>
            </table>

        </div>
        <div>
            <button class="btn btn-primary" @click="exportToExcel">Exportar a Excel</button>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"
import helpers from "@/resources/helpers/helpers"
import {createHelpers} from "vuex-map-fields"
import ExcelJS from "exceljs"


const {mapFields} = createHelpers({
    getterType: 'dashboardMetrics/getField',
    mutationType: 'dashboardMetrics/updateField',
})

export default {
    name: "InstallationsDetailsView",
    data() {
        return {
            showRowsResume: false,
            showRowsInstallations: false,
            expandedRows: {},
            activeGroups: [],
            localInstallations: [],
            data: [],

            showLoading: false,
            helpers,
        }
    },
    computed: {
        ...mapState('dashboardMetrics', ['dashboard_metrics']),
        ...mapFields({
            dashboard_metrics: 'dashboard_metrics',
            installation_list: 'installation_list',
        }),
    },
    methods: {

        async exportToExcel() {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Instalaciones')

            // Encabezados de columna
            const headers = [
                'Instalación',
                'Clasificación',
                'Documento',
                'Mantenedor',
                'Fecha realizada',
                'Fecha prevista',
                'Tipología',
                'Tipo Instalación',
                'Responsable',
                'Empresa',
            ]

            worksheet.addRow(headers)
            // Datos de la tabla
            this.installation_list.forEach(detail => {
                worksheet.addRow([
                    detail.gei_code,
                    detail.classification,
                    detail.document_name,
                    detail.provider_contract_e_e && detail.provider_contract_e_e[0] && detail.provider_contract_e_e[0].provider && detail.provider_contract_e_e[0].provider.provider ? detail.provider_contract_e_e[0].provider.provider : '',
                    detail.effective_date ? this.helpers.formatDate(detail.effective_date) : '-',
                    detail.expected_date ? this.helpers.formatDate(detail.expected_date) : '-',
                    detail.typology.name,
                    detail.installation_type ?  detail.installation_type.type : '-',
                    detail.master_installation.responsible ? detail.master_installation.responsible.name : '-',
                    detail.master_installation.company ?detail.master_installation.company.name : '-',
                ])
            })

            const columnWidths = [
                70, 25, 100, 20,20, 20, 35, 45, 55, 30,
            ]

            // Establecer anchos fijos para las columnas
            worksheet.columns.forEach((column, index) => {
                column.width = columnWidths[index]
            })

            // Generar el archivo Excel
            const buffer = await workbook.xlsx.writeBuffer()

            // Crear un Blob para la descarga
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})

            // Crear una URL para el Blob
            const url = window.URL.createObjectURL(blob)

            // Crear un enlace de descarga y hacer clic en él
            const a = document.createElement('a')
            a.href = url
            a.download = 'instalaciones.xlsx'
            a.click()

            // Liberar recursos
            window.URL.revokeObjectURL(url)
        }


    },


}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

[dir] .dashboard-metrics {
    font-size: 12px; /* Tamanho de fonte desejado */
}

</style>